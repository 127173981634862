import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "ninua_loan_to_anorthosis" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Catenaccio Sports Agency радо сообщить о соглашении между клуюлм «Анортосис» Фамагуста и футболистом Нико Нинуа, приобретенным на один год в аренду у ПАОК Салоники. Футболист выбрал номер 32.</p>

          <p>Нинуа родился 22 июня 1999 года в Тбилиси, Грузия, и прошёл обучение в академии самой успешной команды страны — «Динамо» Тбилиси. С 2017 года он был переведен в первую команду и сыграл в 99 официальных матчах, забив 14 голов. В 2020 году он перешёл в греческий ПАОК. В прошлом сезоне Нико провел 12 матчей и забил два гола.</p>
        </div>
      </div>
    )
  }
}